<template>
  <mo-top-bar absolute scroll-shadow scroll-target="contentsArea" color="primary" class="ui-top-bar">
     <div slot="nav" @click="navClick" class="icon-wrapper">
      <mo-icon
               color="white"
               icon-size="36px"
               borderless>drawer</mo-icon>
    </div>
    
    
    <div class="ui-gnb-title__main" @click="moveToHome">
      {{ title }}
    </div>
  </mo-top-bar>
</template>

<script>
export default {
  name: 'TopBar',
  methods: {
    navClick() {
      this.$emit('navClick');
    },
    moveToHome() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      } 
    }
  },
  props: {
    title: { type: String, default: 'UI Dev Project' },
    subTitle: { type: String, default: 'SDS Development Platform' },
  },
}
</script>

<style>
.ui-top-bar {
  border-bottom: 1px solid #707070
}
.ui-gnb-title__main {
  font-size: 24px;
  font-weight: bold;
}
</style>